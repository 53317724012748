<template>
  <div>
    <b-card
      v-for="invoice in invoices"
      :key="invoice.id"
    >
      <div class="card-data">
        <h3>Faktura numer: {{ invoice.invoice_number }} Stworzona: {{ invoice.created_at.split('.')[0] }}</h3>
        <a
          target="_blank"
          :href="invoice.pdf_file_link"
          class="btn btn-primary"
        >Pokaz</a>
      </div>
    </b-card>
  </div>
</template>

<script>
import { BCard } from 'bootstrap-vue'
import axios from 'axios'
import Swal from 'sweetalert2'

export default {
  components: { BCard },
  data() {
    return {
      invoices: [],
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      document.title = 'Faktury - Pickmode'

      const config = { headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` } }
      axios.get(`${process.env.VUE_APP_API_URL}business/invoices`, config)
        .then(response => {
          this.invoices = response.data.invoices
        })
        .catch(() => {
          Swal.fire({
            title: 'Błąd',
            text: 'Nie udało się pobrać danych',
            type: 'error',
            icon: 'error',
            confirmButtonText: 'OK',
          })
        })
    },
  },
}
</script>

<style>
.card-data {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
